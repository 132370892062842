import React from 'react';
import AdminReviewDatum from '../../../AdminReviewComponents/AdminReviewDatum';
import AdminReviewSection from '../../../AdminReviewComponents/AdminReviewSection';
import { Col } from 'react-grid-system';
import SectionRow from '../../../SectionRow';
import { formatToggleValue } from '../../../../utils/helpers';
import useStyles from './styles';
import NewTabLink from '../../../NewTabLink';
import {
	ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE,
	ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE
} from '../../../../utils/constants/routes';
import { RETRAINER } from '../../../RetrainerProfileTemplate/constants';

const AcknowledgementsSection = ({ formType, formData }) => {
	const classes = useStyles();
	return (
		<AdminReviewSection title="Acknowledgements">
			<Col>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div>
								<b>I confirm that I have read and agree to abide by:*</b>
								<div className="md:ml-5 !font-normal">
									(1){' '}
									<a
										href={
											formType === RETRAINER
												? 'https://acrobat.adobe.com/id/urn:aaid:sc:AP:fe407e7e-437f-4488-a988-4879b0b07d24'
												: 'https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:2fe3eba4-f7e9-38a0-b609-2291e2b93995'
										}
										target="_blank"
										rel="noreferrer"
										className={classes.mailLink}
									>
										{' '}
										Acknowledged{' '}
										{formType === RETRAINER
											? 'Retrainer'
											: 'Retirement Farm'}{' '}
										Program Terms and Conditions;
									</a>
								</div>
							</div>
						}
						value={formatToggleValue(formData?.acknowledgesAgreement)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div>
								<strong>Equine Welfare:*</strong>
								<br />
								<strong>I acknowledge that I:</strong> <br />
								<div className="md:ml-5 !font-normal">
									(1) Support and promote good equine welfare practices; <br />
									(2) Agree to comply with the{' '}
									<NewTabLink url="https://agriculture.vic.gov.au/livestock-and-animals/animal-welfare-victoria/pocta-act-1986">
										Prevention of Cruelty to Animals Act 1986 (Vic)
									</NewTabLink>{' '}
									and specifically the{' '}
									<NewTabLink url="https://agriculture.vic.gov.au/__data/assets/pdf_file/0008/538271/Code-of-Practice-for-the-Welfare-of-Horses.pdf">
										Code of Practice for the Welfare of Horses
									</NewTabLink>
									{''}
									;
									<br />
									(3) Agree to promptly notify the Racing Victoria Equine
									Welfare Team of any equine welfare concerns that I may
									witness; and
									<br />
									(4) Agree to complete all Off The Track equine welfare
									training programs within the relevant time frame.
									<br />
								</div>
							</div>
						}
						value={formatToggleValue(formData?.acknowledgesEquineWelfare)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div>
								<b>National Police Record Certificate:*</b> <br />
								<div className="!font-normal">
									In circumstances where Racing Victoria&apos;s Intelligence
									Unit sees fit, I consent to obtaining a National Police Record
									Certificate and providing it to Racing Victoria (if contacted
									by Racing Victoria or an appointed CrimTrac accredited
									agency).
								</div>
							</div>
						}
						value={formatToggleValue(
							formData?.acknowledgesNationalPoliceRecordCertificate
						)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div>
								<b>Criminal Charges:*</b>
								<br />
								<div className="!font-normal">
									After submitting this Application, I acknowledge that I must
									inform Racing Victoria&apos;s Intelligence Unit of any
									criminal charges laid against me within seven days of any
									charges being laid. Updates must be sent by email to
									<a
										className={classes.mailLink}
										href="mailto:icu@racingvictoria.net.au"
									>
										{' '}
										icu@racingvictoria.net.au.
									</a>
								</div>
							</div>
						}
						value={formatToggleValue(formData?.acknowledgesCriminalCharges)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div className="!font-normal">
								<b>Personal Bankruptcy and/or Company Insolvency:*</b> <br />
								After submitting this Application, I acknowledge that I must
								inform Racing Victoria&apos;s Intelligence Unit within 7 days by
								email to{' '}
								<a
									className={classes.mailLink}
									href="mailto:icu@racingvictoria.net.au"
								>
									icu@racingvictoria.net.au
								</a>{' '}
								if:
								<div className="md:ml-5">
									(a) I am declared Bankrupt; and/or <br />
									(b) A Company which I use as part of my{' '}
									{formType === RETRAINER ? 'retraining ' : 'retirement farm '}
									function/business, and/or which employs me as a{' '}
									{formType === RETRAINER
										? 'retrainer'
										: 'retirement farm provider'}
									, becomes insolvent (including: Voluntary Administration,
									Liquidation or Receivership).
								</div>
							</div>
						}
						value={formatToggleValue(formData?.acknowledgesBankruptcy)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<b>
								{formType === RETRAINER
									? `If my application is successful, I agree to utilise the OTT
								Community to submit reporting of all OTTs in retraining on a
								minimum of a monthly basis, this includes updating the Retrainer
								Report when the horse has been rehomed, sold or is no longer in
								my care.*`
									: `If my application is successful, I agree to utilise the OTT Community 
								to submit reporting of all OTTs in my care on a minimum of a quarterly basis, 
								this includes updating the Report to advise when a thoroughbred is no longer in my care.`}
							</b>
						}
						value={formatToggleValue(
							formData?.acknowledgesToUtiliseOttCommunity
						)}
						largeScreenWidth={12}
					/>
				</SectionRow>
				<SectionRow>
					<AdminReviewDatum
						label={
							<div>
								<b>I, the applicant hereby:*</b>
								<div className="md:ml-5 !font-normal">
									&bull;&nbsp;Declare that all details in my application are
									true and correct; <br />
									&bull;&nbsp;Acknowledge that Racing Victoria may request
									further information from me in respect of this application;{' '}
									<br />
									&bull;&nbsp;Consent to Racing Victoria obtaining personal
									information about me from others and using & disclosing my
									personal information as noted in the attached
									<a
										href={
											formType === RETRAINER
												? ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE
												: ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
										}
										target="_blank"
										rel="noreferrer"
										className={classes.mailLink}
									>
										{' '}
										Privacy Collection Statement.
									</a>
									<br />
									&bull;&nbsp;Consent to Racing Victoria handling my personal
									information, which includes the collection and disclosure of
									my personal information (including sensitive) to/from third
									parties as set out in the{' '}
									<NewTabLink
										url={
											formType === RETRAINER
												? ACKNOWLEDGED_RETRAINER_PRIVACY_COLLECTION_STATEMENT_ROUTE
												: ACKNOWLEDGED_RETIREMENT_FARM_PRIVACY_COLLECTION_STATEMENT_ROUTE
										}
										className={classes.mailLink}
									>
										Privacy Collection Statement
									</NewTabLink>
									, Racing Victoria&apos;s{' '}
									<NewTabLink
										url="https://www.racingvictoria.com.au/privacy-policy"
										className={classes.mailLink}
									>
										Privacy Policy
									</NewTabLink>
									{'  '}
									or otherwise permitted by law.
								</div>
							</div>
						}
						value={formatToggleValue(
							formData?.acknowledgesCorrectApplicationDetails
						)}
						largeScreenWidth={12}
					/>
				</SectionRow>
			</Col>
		</AdminReviewSection>
	);
};

export default AcknowledgementsSection;
